import { FC, useEffect, useRef, useState } from 'react';

import { Card } from '@/components/ui/card.tsx';
import { DiscountCode } from '@/components/discount-code.tsx';

import { NextOrderInfo } from '@/pages/checkout/components/next-order-info.tsx';
import { FeaturesComponent } from '@/pages/checkout/components/FeatureSection.tsx';
import { DeliveryIconsListing } from '@/pages/checkout/components/delivery-icons-listing.tsx';
import { DeliveryProductInfo } from '@/pages/checkout/components/delivery-product-info.tsx';

import { getMerchantData } from '@/utils/get-merchant-data.ts';

import { useCheckoutDataStore } from '@/store/checkout-store.ts';

import { ChangeToProduct, MerchantData, OrderLine } from '@/core/entities';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion.tsx';
import { useTranslation } from 'react-i18next';
import { formatPriceNormal } from '@/utils/format-price.ts';

export const CheckoutSection: FC = () => {
  const { checkoutData } = useCheckoutDataStore();
  const { t } = useTranslation("translation");
  const params = new URLSearchParams(window.location.search);
  const payDeliveryParam = params.get("payDelivery");
  const payDelivery = payDeliveryParam === "true";
  const [isMobileAccordionOpen, setIsMobileAccordionOpen] = useState(false);

  // Preventing multiple  useEffect runs
  const preventMultipleRender = useRef<boolean>(false);

  console.log('checkoutData',checkoutData);

  useEffect(() => {
    if (!checkoutData) {
      return;
    }

    if (!checkoutData.html_snippet) {
      return;
    }

    const boxElement = document.getElementById('checkout-div');

    if (boxElement) {
      boxElement.innerHTML = checkoutData.html_snippet
        .replace(/\\"/g, '"')
        .replace(/\\n/g, '');

      const scriptsTags = boxElement.getElementsByTagName('script');

      for (let i = 0; i < scriptsTags.length; i++) {
        const parentNode = scriptsTags[i].parentNode;
        const newScriptTag = document.createElement('script');
        newScriptTag.type = 'text/javascript';
        newScriptTag.text = scriptsTags[i].text;
        parentNode?.removeChild(scriptsTags[i]);
        parentNode?.appendChild(newScriptTag);
      }
    }
  }, [checkoutData]);

  useEffect(() => {
    if (!checkoutData) {
      return;
    }

    if (!checkoutData.html_snippet) {
      return;
    }

    // If the effect has run more than once, return early to prevent further execution
    if (preventMultipleRender.current) {
      return;
    }


      try {
        const value = Number((checkoutData.order_amount / 100).toFixed(2));
        // Track Initiate checkout Facebook
        // eslint-disable-next-line
        // @ts-ignore
        fbq('trackCustom', 'Initiate checkout', {
          value,
          currency: 'SEK',
        });

        // Track Initiate checkout TikTok
        //eslint-disable-next-line
        // @ts-ignore
        ttq.track('InitiateCheckout');

        const pintrkLineItems = checkoutData.order_lines
          .map((item, index) => ({
            product_name: item.name,
            product_id: item.name + index,
            product_price: Number((item.unit_price / 100).toFixed(2)),
            product_quantity: item.quantity
          }));

        // Track visited page Pinterest
        // eslint-disable-next-line
        // @ts-ignore
        pintrk('track', 'pagevisit', {
          currency: 'SEK',
          line_items: pintrkLineItems
        });

        // const pintrkData = {
        //   value,
        //   currency: 'SEK',
        //   order_quantity: '1',
        //   line_items: pintrkLineItems
        // };

        // Track added to card Pinterest
        // eslint-disable-next-line
        // @ts-ignore
        // pintrk('track', 'addtocart', pintrkData);
      } catch (e) {
        console.log('FB Initial error', e);
      }

    // Increment the count every time useEffect runs
    preventMultipleRender.current = true;
  }, [checkoutData, payDelivery]);


  type GetNextOrderDataReturn = {
    changeToProduct: ChangeToProduct;
    metadata: MerchantData;
  };

  const getNextOrderData = (
    orderLines: OrderLine[],
  ): GetNextOrderDataReturn | null => {
    let changeToProduct: ChangeToProduct | null = null;

    let changeToProductMetadata: MerchantData | null = null;

    if (!checkoutData) {
      return null;
    }

    if (!orderLines) {
      return null;
    }

    orderLines.forEach((orderLine) => {
      if (!orderLine.merchant_data) {
        return;
      }
      const metadata = getMerchantData(orderLine.merchant_data);

      if (!metadata) {
        return;
      }

      if (metadata.changeToProduct) {
        changeToProduct = metadata.changeToProduct;
        changeToProductMetadata = metadata;
      }

      if (
        !metadata.changeToProduct &&
        metadata.isSubscription &&
        metadata.isBundle &&
        metadata.capsulesConfig
      ) {
        changeToProduct = {
          name: orderLine.name,
          price: orderLine.unit_price / 100,
          image: orderLine.image_url || "",
          capsulesConfig: {
            espresso: metadata.capsulesConfig.espresso || 0,
            ristretto: metadata.capsulesConfig.ristretto || 0,
            lungo: metadata.capsulesConfig.lungo || 0,
            decaf: metadata.capsulesConfig.decaf || 0,
          },
        };
        changeToProductMetadata = metadata;
      }
    });

    if (!changeToProduct) {
      return null;
    }

    if (!changeToProductMetadata) {
      return null;
    }

    return {
      changeToProduct: changeToProduct,
      metadata: changeToProductMetadata,
    };
  };

  if (!checkoutData) {
    return <div>No checkout data</div>;
  }

  const nextOrderProduct = getNextOrderData(checkoutData.order_lines);

  return (
    <div className={"h-fit"}>
      <div
        className={"grid grid-cols-1 pt-12 lg:grid-cols-2  space-y-6 lg:gap-16"}
      >
        <DeliveryIconsListing className={"lg:hidden mb-5"} />

        <Accordion className={"lg:hidden"} type="single" collapsible>
          <AccordionItem value="item-1" className={"border-b-0"}>
            <AccordionTrigger
              onClick={() => setIsMobileAccordionOpen(!isMobileAccordionOpen)}
              className={
                "flex justify-between transition ease-in-out duration-200"
              }
            >
              {isMobileAccordionOpen ? t("hideOrder") : t("showOrder")}
              <span
                className={
                  "flex flex-1 items-center justify-end pr-2 font-black"
                }
              >
                {formatPriceNormal(checkoutData.order_amount / 100)}
              </span>
            </AccordionTrigger>
            <AccordionContent>
              <DeliveryProductInfo
                orderTotal={checkoutData.order_amount}
                className={"lg:hidden"}
                products={checkoutData.order_lines}
                shippingOptions={checkoutData.shipping_options}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <Card className={" h-fit lg:sticky lg:top-4"}>
          <div className={"p-4 sm-p-8"} id="checkout-div" />
        </Card>

        <div className={"lg:p-8 space-y-6"}>
          <DeliveryIconsListing className={"hidden lg:block"} />

          <DeliveryProductInfo
            orderTotal={checkoutData.order_amount}
            className={"hidden lg:block"}
            products={checkoutData.order_lines}
            shippingOptions={checkoutData.shipping_options}
          />

          {!checkoutData.merchant_data?.includes("subscriptionID") && (
            <DiscountCode className={""} />
          )}

          {nextOrderProduct && (
            <NextOrderInfo
              className={""}
              merchantData={nextOrderProduct.metadata}
              changeToProduct={nextOrderProduct.changeToProduct}
            />
          )}
          <FeaturesComponent className={""} />
        </div>
      </div>
    </div>
  );
};

import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@/components/ui/badge.tsx';
import { AspectRatio } from '@/components/ui/aspect-ratio.tsx';

import { CapsulesConfig } from '@/pages/checkout/components/CapsulesConfig.tsx';

import { MerchantData } from '@/core/entities';

import fallbackImage from '/fallback.png';
import { useCheckoutDataStore } from '@/store/checkout-store.ts';
import { formatPriceNormal } from '@/utils/format-price.ts';

type ProductItemProps = {
  image?: string;
  name: string;
  quantity: number;
  price: string;
  merchantData?: MerchantData | null;
};

// type AdditionalProductItem = {
//   image: string;
//   name: string;
//   productID: string;
//   quantity: number;
//   title: string;
//   variantID: string;
//   variantTitle: string;
// }

// Check if the image is valid
async function checkImage(url: string) {
  const res = await fetch(url);
  const buff = await res.blob();

  return buff.type.startsWith("image/");
}


export const ProductItem: FC<{ product: ProductItemProps; }> = ({
  product
}) => {
  const { t } = useTranslation("translation");
  const [hasImage, setHasImage] = useState(false);
  const { checkoutData } = useCheckoutDataStore();

  const merchantData = product.merchantData;

  const additionalProducts = JSON.parse(checkoutData ? checkoutData?.merchant_data : '{}');

  console.log('additionalProducts', additionalProducts);
  const variantID = merchantData?.shopifyVariantID || '';
  console.log('variantID', variantID);

  console.log('Object.keys(additionalProducts).length',Object.keys(additionalProducts).length)
  const productPriceValue = parseFloat(product.price) * product.quantity;

  console.log('additionalProducts[variantID]',additionalProducts[variantID]);

  const productName = useMemo(() => {
    if(!product.name){
      return '';
    }

    if(product.name.includes('&amp;')) {
      return product.name.replace('&amp;', '+');
    }

    return product.name
  },[product.name])


  useEffect(() => {
    if (product.image) {
      checkImage(product.image).then((res) => {
        setHasImage(res);
      });
    }
  }, [product.image]);

  if (!product) {
    return null;
  }

  return (
    <div
      className={
        "flex justify-between lg:gap-2 flex-wrap gap-4 items-start flex-row w-full"
      }
    >
      <div
        className={"grid grid-cols-1 sm:grid-cols-3 items-start gap-2 w-full max-w-[320px]"}
      >
        <div className="col-span-1 w-24 h-full">
          <AspectRatio ratio={4 / 3}>
            <img
              src={hasImage ? product.image : fallbackImage}
              alt={product.name}
              className="rounded-sm border shadow-sm aspect-auto object-contain"
            />
          </AspectRatio>
        </div>

        <div className={"col-span-2 w-fit justify-start items-start"}>
          <div className={" flex flex-col w-full space-y-2"}>
            <div className={"lg:w-fit"}>
              <h1 className={"text-base font-bold"}>
                {productName}
              </h1>

              <div
                className={
                  "flex flex-col sm:flex-row text-sm font-extralight text-muted-foreground leading-none my-3"
                }
              >
                {product.quantity} x {product.price}
              </div>
            </div>

            <div className="text-sm italic font-extralight text-muted-foreground leading-none">
              {merchantData?.capsulesConfig && (
                <CapsulesConfig capsulesConfig={merchantData.capsulesConfig}/>
              )}

              {/*<div className={'flex flex-wrap gap-1 mt-1'}>*/}
              {/*  {Object.keys(additionalProducts).length > 0 && additionalProducts[variantID] && (*/}
              {/*    <div>*/}
              {/*      {additionalProducts[variantID].additionalProducts*/}
              {/*        .filter((x: {*/}
              {/*          name: string*/}
              {/*        }) => !x.name.includes('product-type')) // TODO: We should filter this in backend*/}
              {/*        .map((item: AdditionalProductItem, index: number) => {*/}
              {/*          return (*/}
              {/*            <Badge key={index} variant={'secondary'}>*/}
              {/*              {item.title} - {item.quantity}*/}
              {/*            </Badge>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</div>*/}
            </div>

            <div className="text-sm italic font-extralight text-muted-foreground leading-none">

            </div>

            <aside className="text-sm font-extralight text-muted-foreground leading-none">
              {merchantData?.isBundle && (
                <div className={'flex gap-3 flex-wrap'}>
                  <Badge variant="default">
                    {merchantData?.isBundle && t('bundle')}
                  </Badge>
                </div>
              )}
            </aside>
          </div>
        </div>
      </div>
      <div className={"font-bold text-lg flex justify-end items-start w-full"}>
        <span>{formatPriceNormal(productPriceValue)}</span>
      </div>
    </div>
  );
};

import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card.tsx';

import { cn } from '@/lib/utils.ts';

interface FeaturesComponentProps extends HTMLAttributes<HTMLDivElement> {
}

export const FeaturesComponent: FC<FeaturesComponentProps> = (props) => {
  const { t } = useTranslation("translation");

  return (
    <Card
      className={cn(
        props.className,
        'lg:max-w-xl mx-auto p-6'
      )}
    >
      <CardHeader>
        <CardTitle className="text-2xl tracking-wide font-bold text-center mb-6">
          {t("whyOver")}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-8">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">📦</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              {t("easyRefunds")}
            </h3>

            <p>{t("refundsInMonth")}</p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">🤎</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              {t("joinMovement")}
            </h3>
            <p>{t("overCustomers")}</p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">☕</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              {t("fourUniqueFlavors")}
            </h3>

            <p>{t("eachBlend")}</p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0 text-4xl">👅</div>
          <div>
            <h3 className="text-xl font-semibold tracking-wide">
              {t("tasting")}
            </h3>
            <p>{t("systemToDelivery")}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
